import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant
import { AxiosError } from "axios";
import { router } from "@/router/router";

export function useBuyersGuideApi() {
  const getBuyersGuidesRequest = async (
    plotId: string,
    params: object = {},
  ) => {
    const queryParams = {
      page: 1,
      ...params,
    };

    try {
      return await axios.get(
        `${apiUrl}/api/plots/${plotId}/buyers-guide-sections`,
        { params: queryParams },
      );
    } catch (error) {
      throw handleError(error);
    }
  };

  const getBuyersGuideRequest = async (id: string, plotId: string) => {
    try {
      return await axios.get(
        `${apiUrl}/api/plots/${plotId}/buyers-guide-sections/${id}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  };

  const handleError = (error: any) => {
    if (error instanceof AxiosError && error.response) {
      if (error.response.status === 404) {
        router.push({ name: "404" });
        return "404 - not found;";
      }

      return error.response.data;
    }

    return error;
  };

  return {
    getBuyersGuidesRequest,
    getBuyersGuideRequest,
  };
}
