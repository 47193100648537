import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useAppStore } from "./appStore";
import { useOrdersApi } from "@/helpers/useOrdersApi";

import {
  OrderProductType,
  OrderType,
  ProductCondensedType,
  ProductType,
} from "@/types/StripeTypes";
import { OrderStatus, OrderTypes } from "@/enums/Orders";
import {
  ApiCreateOrderRequestBody,
  ApiSearchOrdersParams,
  ApiSearchOrdersResponse,
} from "@/types/ApiTypes";
import { useStripeApi } from "@/helpers/useStripeApi";

const { getOrderRequest, createOrderRequest, searchOrdersRequest } =
  useOrdersApi();

dayjs.extend(utc);

export let useOrderStore = defineStore("order", {
  state: (): OrderState => ({
    order: {
      id: 0,
      uuid: "",
      type: "",
      version: "",
      total_price: 0,
      status: OrderStatus.Pending,
      expires_at: null,
      order_products: [
        {
          id: 0,
          product_id: 0,
          quantity: 0,
          price_paid: 0,
          cost_price: 0,
          actual_price: 0,
        },
      ],
      stripe_payment_intent: null,
    },
    allOrders: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: "",
        per_page: 1,
        to: 1,
        total: 1,
      },
    },
    stripeKey: "",
    isCreatingOrder: false,
  }),

  persist: true,

  actions: {
    async createOrder(order: ApiCreateOrderRequestBody) {
      const appStore = useAppStore();
      try {
        appStore.setLoading(true);
        const response = await createOrderRequest(order);
        if (response.status === 201) {
          this.order = response.data.data; // Ensure reactive assignment
          return this.order; // Return the created order
        } else {
          console.error(response);
          throw new Error("Failed to create order");
        }
      } catch (error) {
        console.error("Error in createOrder:", error);
        throw error; // Re-throw for the caller to handle
      } finally {
        appStore.setLoading(false);
      }
    },

    async getOrder(orderId: number) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      const request = getOrderRequest(orderId);

      request
        .then((response) => {
          if (response.status === 200) {
            this.order = response.data.data;
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    async searchOrders(orderType: OrderTypes, params: ApiSearchOrdersParams) {
      const appStore = useAppStore();

      try {
        appStore.setLoading(true);

        const response = await searchOrdersRequest(orderType, params);

        if (response.status === 200) {
          const data: ApiSearchOrdersResponse = response.data; // Ensure type safety
          this.allOrders = data; // Assign to the reactive property
          if (data.data.length > 0) {
            // Set the last order in the list as the current order
            this.order = data.data[data.data.length - 1];
          } else {
            console.warn("No orders found in search result.");
          }
          console.log(data.data);
        } else {
          console.error("Unexpected response status:", response);
        }
      } catch (error) {
        console.error("Error in searchOrders:", error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async createPaymentIntent(order: OrderType) {
      const appStore = useAppStore();
      const stripeApi = useStripeApi();

      appStore.setLoading(true);

      const request = stripeApi.createPaymentIntentRequest(order);

      request
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.status === OrderStatus.Completed) {
              this.orderCompleted();
            }

            this.order = response.data.data;
          } else {
            console.error(response);

            this.getOrder(order.id);
          }
        })
        .catch((error) => {
          console.error(error);
          this.getOrder(order.id);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    async verifyPaymentIntent(order: OrderType) {
      const appStore = useAppStore();
      const stripeApi = useStripeApi();

      appStore.setLoading(true);

      const request = stripeApi.verifyPaymentIntentRequest(order);

      request
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.status === OrderStatus.Completed) {
              this.orderCompleted();
            }

            this.order = response.data.data;
          } else {
            console.error(response);

            this.getOrder(order.id);
          }
        })
        .catch((error) => {
          console.error(error);
          this.getOrder(order.id);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    async refreshStripeKey() {
      const stripeApi = useStripeApi();

      const request = stripeApi.getStripeKeyRequest();

      request
        .then((response) => {
          this.stripeKey = response.data.publishable_key;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async setupCheckout(orderRequest?: ApiCreateOrderRequestBody) {
      const appStore = useAppStore();

      try {
        appStore.setLoading(true);

        // Find or create the order if it doesn't exist
        if (!this.order.id && orderRequest) {
          // Search for an existing order
          await this.searchOrders(orderRequest.type, {
            plotId: orderRequest.metadata.plot_id,
            withProducts: true,
            active: true,
          });

          if (!this.order.id) {
            // If no existing order found, create a new one
            await this.createOrder(orderRequest);
          }
        }

        // Fetch the updated order if it exists
        if (this.order.id) {
          await this.getOrder(this.order.id);
        }

        // Ensure a payment intent is available
        if (this.order.id && !this.order.stripe_payment_intent) {
          await this.createPaymentIntent(this.order);
        }

        // Refresh the Stripe key
        await this.refreshStripeKey();

        // Final fetch to ensure the order is up-to-date
        if (this.order.id) {
          await this.getOrder(this.order.id);
        } else {
          console.error("Order setup failed.");
        }
      } catch (error) {
        console.error("Error in setupCheckout:", error);
        throw error;
      } finally {
        appStore.setLoading(false);
      }
    },

    makeOrderProducts(products: ProductType[]) {
      return products.map((product) => {
        return {
          product_id: product.id,
          price: product.price,
        } as ProductCondensedType;
      });
    },

    getDate(date: string) {
      return dayjs.utc(date).format("D MMMM");
    },

    orderCompleted() {
      console.log("Order completed");
    },

    getProductName(product: OrderProductType) {
      if (this.order.type === OrderTypes.PlotReservation) {
        const appStore = useAppStore();
        if (appStore.development && appStore.plot) {
          return `Reservation for Plot ${appStore.plot.name} at ${appStore.development.name}`;
        }

        return `Plot Reservation`;
      }

      if (this.order.type === OrderTypes.StudioUpgrade) {
        return "Studio Upgrade - more work needed here";
      }

      return `No name: ${product.product_id}`;
    },
  },

  getters: {},
});

interface OrderState {
  order: OrderType;
  allOrders: ApiSearchOrdersResponse;
  stripeKey: string;
  isCreatingOrder: boolean;
}
