import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant
import { OrderType } from "@/types/StripeTypes";

export function useStripeApi() {
  const getStripeKeyRequest = async () => {
    try {
      return await axios.get(`${apiUrl}/api/stripe/credentials`);
    } catch (error: any) {
      console.error("Error getting Stripe key:", error);

      throw error.response.data.message;
    }
  };

  const createPaymentIntentRequest = async (order: OrderType) => {
    try {
      return await axios.post(
        `${apiUrl}/api/orders/${order.id}/payments/create-intent`,
        { version: order.version },
      );
    } catch (error: any) {
      console.error("Error creating payment intent:", error);

      throw error.response.data.message;
    }
  };

  const verifyPaymentIntentRequest = async (order: OrderType) => {
    try {
      return await axios.post(
        `${apiUrl}/api/orders/${order.id}/payments/verify-intent`,
      );
    } catch (error: any) {
      console.error("Error verifying payment intent:", error);

      throw error.response.data.message;
    }
  };

  return {
    getStripeKeyRequest,
    createPaymentIntentRequest,
    verifyPaymentIntentRequest,
  };
}
