import { defineStore } from "pinia";
import { useBuyersGuideApi } from "@/helpers/useBuyersGuideApi";
import { useAppStore } from "@/stores/appStore";
import {
  ApiFetchBuyersGuidesResponse,
  ApiFetchBuyersGuideResponse,
} from "@/types/ApiTypes";

const { getBuyersGuidesRequest, getBuyersGuideRequest } = useBuyersGuideApi();

export let useBuyersGuideStore = defineStore("buyersGuides", {
  state: (): BuyersGuideState => ({
    guides: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: "",
        per_page: 1,
        to: 1,
        total: 1,
      },
    },
    guide: {
      data: {
        id: 0,
        title: "",
        subtitle: "",
        components: [],
      },
    },
  }),

  actions: {
    async fetchGuides(plotId: string, params: object = {}) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const guides = await getBuyersGuidesRequest(plotId, params);

        this.guides = guides.data;
      } catch (error: any) {
        console.error("Error getting forms: ", error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async fetchGuide(id: string, plotId: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const guide = await getBuyersGuideRequest(id, plotId);

        if (guide.status === 200) {
          this.guide = guide.data;
        } else {
          console.error("Error getting form: ", guide);
        }
      } catch (error: any) {
        console.error("Error getting form: ", error);
      } finally {
        appStore.setLoading(false);
      }
    },
  },
});

interface BuyersGuideState {
  guides: ApiFetchBuyersGuidesResponse;
  guide: ApiFetchBuyersGuideResponse;
}
