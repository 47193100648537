import { defineStore } from "pinia";
import { useAppStore } from "./appStore";
import { useUserApi } from "@/helpers/useUserApi";
import { ApiFetchUserResponse } from "@/types/ApiTypes";

const { getProfileRequest } = useUserApi();

export let useUserStore = defineStore("user", {
  state: (): ApiFetchUserResponse => ({
    data: {
      id: 0,
      name: "",
      email: "",
      role: "",
      plot_ids: [],
      created_at: "",
      updated_at: "",
    },
  }),

  persist: true,

  actions: {
    getProfile() {
      const appStore = useAppStore();

      appStore.setLoading(true);

      const user = getProfileRequest();

      user
        .then((response) => {
          if (response.status === 200) {
            this.data = response.data.data;
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    clearProfile() {
      this.data = {
        id: 0,
        name: "",
        email: "",
        role: "",
        plot_ids: [],
        created_at: "",
        updated_at: "",
      };
    },
  },

  getters: {
    isAdmin(state) {
      return ["admin"].includes(state.data.role);
    },

    isSiteManager(state) {
      return ["site_manager", "admin"].includes(state.data.role);
    },

    isCustomer(state) {
      return ["customer"].includes(state.data.role);
    },

    isStaff(state) {
      // Customer roles: 'customer'
      // Staff roles: 'admin', 'ceo', 'construction_director', 'regional_managing_director', 'sales_and_marketing_director', 'sales_negotiator', 'site_manager'
      return !["customer"].includes(state.data.role);
    },

    hasMultiplePlots() {
      const appStore = useAppStore();

      return appStore.plots?.data && appStore.plots.data.length > 1;
    },

    profile(state) {
      return state.data;
    },

    hasRole: (state) => (role: string[]) => {
      return role.includes(state.data.role);
    },
  },
});
