import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { router } from "@/router/router";
import { useAuthApi } from "@/helpers/useAuthApi";
import { useCookies } from "@vueuse/integrations/useCookies";
import { useAppStore } from "./appStore";
import { useFormStore } from "./formStore";
import { useUserStore } from "./userStore";
import { useOrderStore } from "./orderStore";
import { useBuyersGuideStore } from "./buyersGuideStore";
import { useNotificationsStore } from "./notificationStore";

const {
  loginUserRequest,
  logoutRequest,
  refreshLoginTokenRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
} = useAuthApi();

dayjs.extend(utc);

export const authCookie = useCookies(["auth"]);

export let useAuthStore = defineStore("auth", {
  state: (): UserState => ({
    loginForm: {
      username: "",
      password: "",
      errors: [],
      success: [],
    },

    forgotPasswordForm: {
      username: "",
      errors: [],
      success: [],
    },

    passwordResetForm: {
      username: "",
      token: "",
      password: "",
      passwordConfirm: "",
      errors: [],
      success: [],
    },
  }),

  actions: {
    handleLoginFormSubmit() {
      this.clearAllMessages(this.loginForm);
      const appStore = useAppStore();

      appStore.setLoading(true);

      if (this.loginForm.username && this.loginForm.password) {
        const login = loginUserRequest({
          email: this.loginForm.username,
          password: this.loginForm.password,
        });

        login
          .then((response) => {
            if (response.status === 200) {
              this.setLoginAuthCookie(response.data);

              this.login();
            } else {
              console.error(response);

              this.clearAuthCookie();

              this.addFormError("Invalid username or password", this.loginForm);
            }
          })
          .catch((error) => {
            this.clearAuthCookie();

            this.addFormError(error, this.loginForm);
          })
          .finally(() => {
            appStore.setLoading(false);
          });
      } else {
        this.clearAuthCookie();

        appStore.setLoading(false);

        this.addFormError("Missing email or password", this.loginForm);
      }
    },

    async handleForgotPasswordFormSubmit() {
      this.clearAllMessages(this.forgotPasswordForm);

      return await forgotPasswordRequest(this.forgotPasswordForm.username);
    },

    async handlePasswordResetFormSubmit() {
      this.clearAllMessages(this.passwordResetForm);
      const appStore = useAppStore();

      appStore.setLoading(true);

      if (
        this.passwordResetForm.username &&
        this.passwordResetForm.token &&
        this.passwordResetForm.password &&
        this.passwordResetForm.passwordConfirm &&
        this.passwordResetForm.password ===
          this.passwordResetForm.passwordConfirm
      ) {
        return await resetPasswordRequest(
          this.passwordResetForm.token,
          this.passwordResetForm.username,
          this.passwordResetForm.password,
          this.passwordResetForm.passwordConfirm,
        );
      }

      this.addFormError("Missing required fields", this.passwordResetForm);

      return Promise.reject("Missing required fields");
    },

    login() {
      // Forward them to their account
      router.push({ name: "development-select" });

      // Clear the login form
      this.clearLoginForm();
    },

    async refreshLogin() {
      // refresh the login
      const appStore = useAppStore();

      appStore.setLoading(true);

      return await refreshLoginTokenRequest()
        .then((response) => {
          if (response.status === 200) {
            this.setLoginAuthCookie(response.data);
          } else {
            console.error(response);
            throw new Error("Error refreshing login token: " + response);
          }
        })
        .catch((error) => {
          console.log("Error refreshing login token", error);
          throw new Error("Error refreshing login token");
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    logout() {
      const logout = logoutRequest();
      const appStore = useAppStore();
      const formStore = useFormStore();
      const userStore = useUserStore();
      const orderStore = useOrderStore();

      const actions = () => {
        router.push({ name: "login" });
        this.clearAuthCookie();
        this.emptyAllStores();
      };

      logout
        .then((response) => {
          if (response.status === 200) {
            console.log("Logged out");
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          actions();
        })
        .finally(() => {
          actions();
        });
    },

    emptyAllStores() {
      useAppStore().$reset();
      useFormStore().$reset();
      useUserStore().$reset();
      useOrderStore().$reset();
      useBuyersGuideStore().$reset();
      useNotificationsStore().$reset();
      this.$reset();
    },

    getEmptyAuth() {
      const auth: ApiAuth = {
        token: "",
        refresh_token: "",
        expiry: "",
        refresh_expiry: "",
        isLoggedIn: false,
      };

      return auth;
    },

    getEmptyLoginForm(): UserForm {
      return {
        username: "",
        password: "",
        errors: [],
        success: [],
      };
    },

    clearLoginForm() {
      this.loginForm = this.getEmptyLoginForm();
    },

    getEmptyPasswordResetForm(): PasswordResetForm {
      return {
        username: "",
        token: "",
        password: "",
        passwordConfirm: "",
        errors: [],
        success: [],
      };
    },

    clearPasswordResetForm() {
      this.passwordResetForm = this.getEmptyPasswordResetForm();
    },

    addFormError(message: string, form: UserForm) {
      form.errors.push({ message });
    },

    clearFormErrors(form: UserForm) {
      form.errors = [];
    },

    addFormSuccess(message: string, form: UserForm) {
      form.success.push({ message });
    },

    clearFormSuccess(form: UserForm) {
      form.success = [];
    },

    clearAllMessages(form: UserForm) {
      this.clearFormErrors(form);
      this.clearFormSuccess(form);
    },

    setLoginAuthCookie(data: ApiAuth) {
      authCookie.set("auth", data as ApiAuth, {
        path: "/",
        sameSite: "strict",
        expires: dayjs.utc(data.refresh_expiry).toDate(),
      });
    },

    clearAuthCookie() {
      authCookie.set("auth", false as boolean, {
        path: "/",
      });
    },
  },

  getters: {
    isLoggedIn(state) {
      const authCookieValue = authCookie.get<ApiAuth>("auth");

      return authCookieValue;
    },

    isTokenExpired() {
      const authCookieValue = authCookie.get<ApiAuth>("auth");

      if (!authCookieValue) return true; // No cookie

      const now = dayjs.utc().toDate();
      const expiryDate = dayjs.utc(authCookieValue.expiry).toDate();

      console.log([
        "=== Token expiry ===",
        { expiryDate, now },
        expiryDate < now,
      ]);

      return expiryDate < now;
    },

    isRefreshTokenExpired() {
      const authCookieValue = authCookie.get<ApiAuth>("auth");

      if (!authCookieValue) return true; // No cookie

      const now = dayjs.utc().toDate();
      const expiryDate = dayjs.utc(authCookieValue.refresh_expiry).toDate();

      return expiryDate < now;
    },

    getAuthCookie(): ApiAuth {
      const authCookieValue = authCookie.get<ApiAuth>("auth");

      return authCookieValue;
    },

    getToken(): string | false {
      const authCookieValue = authCookie.get("auth") as ApiAuth;

      return authCookieValue?.token ?? false;
    },

    getRefreshToken(): string | false {
      const authCookieValue = authCookie.get("auth") as ApiAuth;

      return authCookieValue?.refresh_token ?? false;
    },
  },
});

interface GenericForm {
  errors: FormErrors[];
  success: FormSuccess[];
}

interface UserForm extends GenericForm {
  username: string;
  password?: string;
}

interface PasswordResetForm extends GenericForm {
  username: string;
  token: string;
  password: string;
  passwordConfirm: string;
}

interface FormSuccess {
  message: string;
}

interface FormErrors {
  message: string;
}

interface UserState {
  loginForm: UserForm;
  forgotPasswordForm: UserForm;
  passwordResetForm: PasswordResetForm;
}

interface ApiAuth {
  token: string;
  refresh_token: string;
  expiry: string;
  refresh_expiry: string;
  isLoggedIn: boolean;
}
